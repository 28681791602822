/*Страница объявления*/
.specific-title,
.specific-value {
    width: 50%;
    text-align: left;
    font-size: 12px;
    float: left;
}
.specific-title {
    color: #686868;
}
.specific-value {
    color: #000000;
}
/**/
.ads-block__seller-block__descrip {
    width: 70%;
    float: left;
    font-size: 12px;
}
.ads-block__seller-block__logo {
    width: 30%;
    font-size: 12px;
    float: left;
}
.ads-block__seller-block__contacts {
    padding-top: 10px;
}





/*Платная услуга для объявлений - VIP*/
.vip {

    box-shadow: 0 0 6px gray;
}
.vip a h6 {
    color: white;
    background-color: #0398db;
}
.vip a:hover h6 {
    color: #0398db;
    background-color: white;
}
.vip .ads_buy {
    color: #c82333;
}
.vip .ads_sell {
    color: #28a745;
}
/*Платная услуга для объявлений - выделение цветом*/
.highlight a h6 {
    color: white;
    background-color: #0398db;
}
.highlight a:hover h6 {
    color: #0398db;
    background-color: white;
}
.highlight .ads_buy {
    color: #c82333;
}
.highlight .ads_sell {
    color: #28a745;
}
/**/
.ads:hover {
    background-color: #eee;
}
.ads {
    padding: 10px;
    overflow: hidden;
}
.ads a {
    text-decoration: none;
}
.ads a:hover h6 {
    color: #c82333;
}
.ads h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0398db;
    margin: 2px 0;
    font-size: 0.9em;
}
.ads span {
    padding-left: 15px;
    color: #686868;
}
.ads p {
    padding: 0;
    margin: 0;
    color: #686868;
    font-size: 0.8em;
}
.ads .price {
    color: black;
    font-size: 0.8em;
    font-weight: bold;
}
.ads .address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ads_wrapper {
    width: 190px;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ads_wrapper a {
    display: contents;
}
.ads_wrapper a img {
    height: 100%;
    width: auto;
    overflow: hidden;
}
/* Вид отображения списком*/
.ads_list {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.ads_list .ads_wrapper {
    float: left;
    margin-right: 30px;
}

/*******************  *******************/
/*******************  *******************/
/*******************  *******************/
.rating, .rating:hover {
    color: #ffc107;
    font-size: 12px;
}
/*******************  *******************/
.advert-secondary-text {
    color: #686868;
    font-size: 12px;
}
/*******************  *******************/
.advert-thumb .advert-caption,
.advert-thumb .advert-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.advert-thumb .advert-price {
    font-weight: bold;
}
.advert-wrapper-descrip {
    height: 40px;
}
.advert-wrapper .advert-caption {
    font-size: 12px;
}
.advert-wrapper .advert-price {
    font-size: 12px;
}
.advert-thumb p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.advert-thumb a {
    text-decoration: none;
}
.advert-thumb:hover {
    background: #eeeeee;
    /*cursor: pointer;*/
}
.advert-thumb:hover .btn-ask {
    display: block;
}
.advert-thumb .btn-block {
    left: 0;
    bottom: 0;
}

.btn-ask {
    display: none;
}

.top-color {
    /*background: #eeeeee;*/
}
.top-color .advert-caption {
    background: #03a9f4;
    color: #ffffff;
}
.top-color .fa-legal {
    color: #28a745!important;
}
.top-color .fa-search {
    color: #c82333!important;
}
.short-descrip-on .short-descrip {
    display: block;
}
.short-descrip-off .short-descrip {
    display: none;
}
