/*Общие настройки*/
html, body {
    font-family: Roboto;
    font-size: 14px;
    scrollbar-width: thin;
}
/**/
/************************ Глобальные стили ************************/
/**/
.h6 {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5;
}
.h5 {
    font-weight: normal;
    font-size: 14px;
}
.h4 {
    font-size: 16px;
}
/* Настройки цветов */
.border-light {
    border-color: #e9ecef !important;
}
/**/
.bg-light-blue {
    background: #dbf1ff;
}
/**/
.bg-primary {
    background-color: #03a9f4!important;
}
.bg-primary-dark {
    background-color: #0398db!important;
}
.bg-success-dark {
    background-color: #1c7430!important;
}
.bg-danger-dark {
    background-color: #b21f2d!important;
}
/* Настройки размеров */
.fs-06 {
    font-size: 0.6em;
}
.fs-07 {
    font-size: 0.7em;
}
.fs-12 {
    font-size: 1.2em;
}
.fs-16 {
    font-size: 1.6em;
}
/* Вспомогательные классы оформления */
.text-decoration-none a:hover {
    text-decoration: none;
}


/************************ Навигационное меню ************************/
/**/
@media (min-width: 768px) {
    .main-menu {
        background-color: #ffffff!important;
    }
}
/* Десктопное меню */
.main-menu-icon-link:hover {
    text-decoration: none;
}
.main-menu-icon-link .badge {
    top: 0;
    right: 0;
}
.main-menu .dropdown-menu {
    min-width: 20rem;
}
/**/
/************************ Общий контент для всех страниц ************************/
/**/
/* Хлебные крошки */
.breadcrumb a:hover {
    color: #0398db;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: '>';
}
/* Блок Поиска простой (на всех страницах, кроме главной и детального поиска) */
.search {
    margin-top: 25px;
    padding: 3px 1px;
    background-color: #0398db;
    border-radius: 4px;
}
.search>div {
    padding: 5px;
}
@media (min-width: 992px) {
    .search_btn-search {
        width: 100%;
    }
}
/**/





/**/
/************************Подвал сайта************************/
/**/
.footer a:hover {
    font-weight: bold;
}





/**/
/***************Переопределение свойств при изменении размера****************/
/**/
@media (max-width: 992px) {
    .modal a {
        width: 100%;
    }
}
