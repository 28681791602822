/* Вид блока категорий на главной странице */
.category-main a:hover {
    text-decoration: none;
}
.category-main a {
    color: #000000;
    font-size: 14px;
}

.category-main .category-main-print button:hover .fa-print,
.category-main .category-main-phone button:hover .fa-phone,
.category-main .category-main-tablet button:hover .fa-tablet,
.category-main .category-main-laptop button:hover .fa-laptop,
.category-main .category-main-tv button:hover .fa-tv,
.category-main .category-main-desktop button:hover .fa-desktop,
.category-main .category-main-zip button:hover .fa-leaf,
.category-main .category-main-other button:hover .fa-spinner {
    color: #ffffff!important;
}

.category-main .category-main-print button .fa-print {
    color: #0398db!important;
}
.category-main .category-main-print button:hover .fa-circle {
    color: #0398db!important;
}
.category-main .category-main-phone button .fa-phone {
    color: #34ce57!important;
}
.category-main .category-main-phone button:hover .fa-circle {
    color: #34ce57!important;
}
.category-main .category-main-tablet button .fa-tablet {
    color: #6f42c1!important;
}
.category-main .category-main-tablet button:hover .fa-circle {
    color: #6f42c1!important;
}
.category-main .category-main-laptop button .fa-laptop {
    color: #c69500!important;
}
.category-main .category-main-laptop button:hover .fa-circle {
    color: #c69500!important;
}
.category-main .category-main-tv button .fa-tv {
    color: #c82333!important;
}
.category-main .category-main-tv button:hover .fa-circle {
    color: #c82333!important;
}
.category-main .category-main-desktop button .fa-desktop {
    color: #ff4500!important;
}
.category-main .category-main-desktop button:hover .fa-circle {
    color: #ff4500!important;
}
.category-main .category-main-zip button .fa-leaf {
    color: deeppink!important;
}
.category-main .category-main-zip button:hover .fa-circle {
    color: deeppink!important;
}
.category-main .category-main-other button .fa-spinner {
    color: #686868!important;
}
.category-main .category-main-other button:hover .fa-circle {
    color: #686868!important;
}
/* Настройка модальных окон для блока категорий на главной странице */
.category-main-modal a:hover {
    text-decoration: none;
}



/********************Блок доступных категорий********************/
.permiss_category {
    margin-top: 30px;
    margin-bottom: 20px;
}
.permiss_category a {
    text-decoration: none;
    color: gray;
    font-size: 0.9em;
    margin-right: 15px;
    white-space: nowrap;
}
.permiss_category a:hover {
    color: #0398db;
}
.permiss_category span {
    padding-left: 10px;
    color: gray;
    font-size: 0.7em;
    white-space: nowrap;
}
/********************Блок фильтров категорий********************/
.category_filter {
    background: #0398db;
    border-radius: 4px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.category_filter label {
    color: white;
    padding-top: 8px;
    margin-bottom: 0;
}
.checkSafeTrade {
    margin-top: 15px;
}
.filter_caption {
    padding: 0;
}
.filter_caption p {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 0;
}
.filter_caption .btn-warning {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
}
/***************Блок объявлений*********************/
.block_ads {
    padding-left: 25px
}
/*Блок VIP объявлений*/
.block_vip_ads {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
}
/*Строка выбора сортировки, количества и вида отображения*/
.sort {
    padding: 0 0 20px 0;
}

/*Навигация по страницам внизу экрана*/
.navigation_pages {
    padding-top: 50px;
}




/**/

/*Разметка блоков под баннерную рекламу*/
.banner_category_footer {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    background: #eee;
}
.banner_category_left {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    background: #eee;
}
